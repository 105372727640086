import _helper from "../helper";
import _ActionSet from "../ActionSet";
import _types from "../types";
var exports = {};
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.capture = exports.openCamera = exports.Scanner = exports.Action = void 0;
var helper_1 = _helper;
var ActionSet_1 = _ActionSet;
var types_1 = _types;
/**
 * Action Types for the Features group
 * @public
 */
var Action;
(function (Action) {
  Action["OPEN_CAMERA"] = "APP::SCANNER::OPEN::CAMERA";
  Action["CAPTURE"] = "APP::SCANNER::CAPTURE";
})(Action = exports.Action || (exports.Action = {}));
/**
 * A set of Actions for displaying a Camera Scanner component
 * @public
 */
var Scanner = /** @class */function (_super) {
  __extends(Scanner, _super);
  function Scanner(app, options) {
    return _super.call(this, app, types_1.Group.Scanner, types_1.Group.Scanner, options ? options.id : undefined) || this;
  }
  /**
   * @public
   */
  Scanner.prototype.dispatch = function (action) {
    switch (action) {
      case Action.OPEN_CAMERA:
        this.dispatchScannerAction(Action.OPEN_CAMERA);
        break;
    }
    return this;
  };
  /**
   * @internal
   */
  Scanner.prototype.dispatchScannerAction = function (type) {
    this.app.dispatch(helper_1.actionWrapper({
      type: type,
      group: types_1.Group.Scanner,
      payload: {
        id: this.id
      }
    }));
  };
  return Scanner;
}(ActionSet_1.ActionSet);
exports.Scanner = Scanner;
function openCamera() {
  return helper_1.actionWrapper({
    group: types_1.Group.Scanner,
    type: Action.OPEN_CAMERA
  });
}
exports.openCamera = openCamera;
function capture(payload) {
  return helper_1.actionWrapper({
    group: types_1.Group.Scanner,
    type: Action.CAPTURE,
    payload: payload
  });
}
exports.capture = capture;
export default exports;
export const __esModule = exports.__esModule;
const _capture = exports.capture,
  _openCamera = exports.openCamera,
  _Scanner = exports.Scanner,
  _Action = exports.Action;
export { _capture as capture, _openCamera as openCamera, _Scanner as Scanner, _Action as Action };